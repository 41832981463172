import React from "react";
import Layout from "../containers/layout/layout";
import Banner from "../containers/blog/banner";
import Blog from "../containers/blog/blog-area";

export default () => (
    <Layout>
        <Banner />
        <Blog />
    </Layout>
);
